const ROUTES = {
  "/": {
    "name": "HomePageBd0f0eda63274b57Ab899b148bd27274",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages6c7f6262005a4a389a3470431646fa63",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage49849303666f4ad99db266251e09cda9",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPage5f74d8d15fc34fc898c8984360a729cb",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage2b8042e0996c4a9f9e7eB0d30c9c4cd8",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage0dd3e957294d49949bd3Ed90d26fd30a",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPagesAcdebf29D623460aAdc0Fa1bc23acade",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages2fb63fa6C611474d8214074a2e3f77bb",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage17ee66eb7e794ea6Bbbe7fc4c09ddb63",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPageF6e95970A05d417cBbc6A3bc5c6fdc48",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesD742b1c01334495dBbd2A71fd57b1410",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;