import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "da5d3e12-f160-4d18-bdaa-0f6e11d73d37"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37(props: any) {
  return (
    <ShortHeader5d72e4c8E28c49c5A5874cbbafb1d19e parentTag="MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37" header-top={<DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopBd283138Dbe24c239ff5D2c49934ce13" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "f6e95970-a05d-417c-bbc6-a3bc5c6fdc48"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageF6e95970A05d417cBbc6A3bc5c6fdc48(props: any) {
  return (
    <SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa parentTag="ResourcesPageF6e95970A05d417cBbc6A3bc5c6fdc48" header={<SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header677ffa2dBd914438B078D18fd405bb45" title="Resources" />} {...props} />
  );
}

// id: "4a9aac4a-8bbe-4722-80d4-6aff7e0acb27"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlug4a9aac4a8bbe472280d46aff7e0acb27 = "about-us-posts";

// id: "b9538f39-ef18-473b-a65f-96bc778834ef"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyB9538f39Ef18473bA65f96bc778834ef(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyB9538f39Ef18473bA65f96bc778834ef page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCards723e94a0C8364fa7909eF15020e33a67", parentTag)} {...props} />}
    </div>
  );
}

// id: "17ee66eb-7e79-4ea6-bbbe-7fc4c09ddb63"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage17ee66eb7e794ea6Bbbe7fc4c09ddb63(props: any) {
  return (
    <LoginPage0dd3e957294d49949bd3Ed90d26fd30a parentTag="PasswordResetPage17ee66eb7e794ea6Bbbe7fc4c09ddb63" {...props} />
  );
}

// id: "6c7f6262-005a-4a38-9a34-70431646fa63"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages6c7f6262005a4a389a3470431646fa63(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages6c7f6262005a4a389a3470431646fa63 gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "da5d2376-4a8f-457f-8d6f-7cea2347c285"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelDa5d23764a8f457f8d6f7cea2347c285(props: any) {
  return (
    <DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="LabelDa5d23764a8f457f8d6f7cea2347c285" {...props} />
  );
}

// id: "2fb63fa6-c611-474d-8214-074a2e3f77bb"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages2fb63fa6C611474d8214074a2e3f77bb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages2fb63fa6C611474d8214074a2e3f77bb gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="HeaderBc45d1858e0f40e9B9c83d283bf2ac0c" {...props} />}
      {props.children}
    </div>
  );
}

// id: "2b8042e0-996c-4a9f-9e7e-b0d30c9c4cd8"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage2b8042e0996c4a9f9e7eB0d30c9c4cd8(props: any) {
  return (
    <SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa parentTag="JoinUsPage2b8042e0996c4a9f9e7eB0d30c9c4cd8" header={<SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header5b178908E91c4cd0A7086f473ac5dde9" title="Join Us" />} {...props} />
  );
}

// id: "28e5f418-ce27-46a8-b7a1-1c8770271bad"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer28e5f418Ce2746a8B7a11c8770271bad(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer28e5f418Ce2746a8B7a11c8770271bad" {...props} />
  );
}

// id: "9430ab5f-1353-4f92-aff3-a9e62ef2a446"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault9430ab5f13534f92Aff3A9e62ef2a446(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault9430ab5f13534f92Aff3A9e62ef2a446 page`}>
      {props["header"] || <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="Header422813eaF3214d94A0a2B80378d247f7" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer94e8411f4ae24ec2B85323ed2945cf9a" {...props} />}
    </div>
  );
}

// id: "49849303-666f-4ad9-9db2-66251e09cda9"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage49849303666f4ad99db266251e09cda9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage49849303666f4ad99db266251e09cda9 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header71a0f4184496446aA16b2311111f560b" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyB9538f39Ef18473bA65f96bc778834ef parentTag="Body44e9fe35Aba4415284c3B73e47f8c2ed" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer84b482ac89324a5e9c71Ac565c64f4ef" {...props} />}
    </div>
  );
}

// id: "9fe4c632-13ff-43d7-a498-85f83e79ca4b"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards9fe4c63213ff43d7A49885f83e79ca4b(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards9fe4c63213ff43d7A49885f83e79ca4b", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "d742b1c0-1334-495d-bbd2-a71fd57b1410"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesD742b1c01334495dBbd2A71fd57b1410(props: any) {
  return (
    <SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa parentTag="SignupPagesD742b1c01334495dBbd2A71fd57b1410" header={<SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header125e8afc745c46b88c49Cb6c9893b997" title="Join Us" />} {...props} />
  );
}

// id: "acdebf29-d623-460a-adc0-fa1bc23acade"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesAcdebf29D623460aAdc0Fa1bc23acade(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesAcdebf29D623460aAdc0Fa1bc23acade gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="HeaderE8dcabb4984f462e80c468d4124c9240" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavE36d45d6768b4f1497402493b2c3a91e", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer7971ffa8C9364de7A2c65fe42dca4b9c" {...props} />}
    </div>
  );
}

// id: "719000f7-285e-48a8-b71e-9cdf98a7dc41"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header719000f7285e48a8B71e9cdf98a7dc41(props: any) {
  return (
    <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="Header719000f7285e48a8B71e9cdf98a7dc41" {...props} />
  );
}

// id: "1ff2fd5d-af38-4e22-bdc2-34e218a92249"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spla/images/spla-logo.png" className={`${parentTag || ""} DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 logo`} alt="logo" />
  );
}

// id: "27ff8055-608d-443c-be7e-2369bc11eca1"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero27ff8055608d443cBe7e2369bc11eca1(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero27ff8055608d443cBe7e2369bc11eca1 hero`}>
      <div className="title">
        Learn. Play. Grow.
      </div>
      <div className="body">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button3ce2fe304ecd4dc7B60699c0622e897a", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "9a9ae194-b664-4b8c-9067-3e30a5eb569a"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "0dd3e957-294d-4994-9bd3-ed90d26fd30a"
export function Header9a9ae194B6644b8c90673e30a5eb569a(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header9a9ae194B6644b8c90673e30a5eb569a" title="Login" {...props} />
  );
}

// id: "3613b4b6-38dd-40bf-9ae2-374a451bf2d7"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "5d72e4c8-e28c-49c5-a587-4cbbafb1d19e"
export function LinkedLogo3613b4b638dd40bf9ae2374a451bf2d7(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo3613b4b638dd40bf9ae2374a451bf2d7", parentTag)} label={<DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="Label8e0f1f341a60430a9973E49acb4d0c71" alt="logo" />} {...props} />
  );
}

// id: "4c6ba97f-6769-4bc8-ab0d-0291a2c4eab4"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "5d72e4c8-e28c-49c5-a587-4cbbafb1d19e"
export function Navigation4c6ba97f67694bc8Ab0d0291a2c4eab4(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation4c6ba97f67694bc8Ab0d0291a2c4eab4", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "5e777ed4-d9c4-4a37-b8f0-bdb57efb7909"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "a979b762-2cdd-49ae-946a-71eb135766bf"
export function Hero5e777ed4D9c44a37B8f0Bdb57efb7909(props: any) {
  return (
    <HomePageHero27ff8055608d443cBe7e2369bc11eca1 parentTag="Hero5e777ed4D9c44a37B8f0Bdb57efb7909" {...props} />
  );
}

// id: "3ce2fe30-4ecd-4dc7-b606-99c0622e897a"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "27ff8055-608d-443c-be7e-2369bc11eca1"
export function Button3ce2fe304ecd4dc7B60699c0622e897a(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button3ce2fe304ecd4dc7B60699c0622e897a", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "5947ee43-cb5e-4778-9472-161650bb5c93"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "422813ea-f321-4d94-a0a2-b80378d247f7"
export const NavMenuSlug5947ee43Cb5e47789472161650bb5c93 = "members-primary-nav";

// id: "bb384fb7-17af-49a0-9a8e-9e8f96d1093e"
// title: ""
// type: :reference
// key: "label"
// parent_id: "509fb79b-7dd2-4d9f-b844-6ed8e4f132d6"
export function LabelBb384fb717af49a09a8e9e8f96d1093e(props: any) {
  return (
    <DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="LabelBb384fb717af49a09a8e9e8f96d1093e" {...props} />
  );
}

// id: "96e032c9-4d2c-46cc-89c1-e7c3ea0e4f1c"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader96e032c94d2c46cc89c1E7c3ea0e4f1c(props: any) {
  return (
    <ShortHeader5d72e4c8E28c49c5A5874cbbafb1d19e parentTag="DefaultHeader96e032c94d2c46cc89c1E7c3ea0e4f1c" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "88e859d2-3476-4714-a296-cf44492a9b1b"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopD2ab371b7eb747df99e4Ee1ac62f8fab" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroF1092649452f488495316ce3b24a55cb parentTag="Hero0a18e3417f574e89Aada07f8a37e1e18" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "ac60ed8d-6bbd-401e-95ed-e1d47de6d927"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "0dd3e957-294d-4994-9bd3-ed90d26fd30a"
export function FooterAc60ed8d6bbd401e95edE1d47de6d927(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterAc60ed8d6bbd401e95edE1d47de6d927" {...props} />
  );
}

// id: "94e8411f-4ae2-4ec2-b853-23ed2945cf9a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9430ab5f-1353-4f92-aff3-a9e62ef2a446"
export function Footer94e8411f4ae24ec2B85323ed2945cf9a(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer94e8411f4ae24ec2B85323ed2945cf9a" {...props} />
  );
}

// id: "5130caf7-2291-45a2-b2d8-6c5261409a56"
// title: ""
// type: :text
// key: "alt"
// parent_id: "1ff2fd5d-af38-4e22-bdc2-34e218a92249"
export const Alt5130caf7229145a2B2d86c5261409a56 = "logo";

// id: "82136c4a-8382-4d5f-8b5a-443fc440cce1"
// title: ""
// type: :text
// key: "title"
// parent_id: "f1092649-452f-4884-9531-6ce3b24a55cb"
export const Title82136c4a83824d5f8b5a443fc440cce1 = "Default Title";

// id: "51b5154c-3b13-47e9-91c5-8fe18f9c1778"
// title: ""
// type: :html
// key: "quote"
// parent_id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
export function Quote51b5154c3b1347e991c58fe18f9c1778(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Quote51b5154c3b1347e991c58fe18f9c1778 PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/fDIDlI3qk75Cn3iS_regular.jpeg" className="image opacity" />
      <div className="TextContent">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
    </div>
  );
}

// id: "bd4acf73-db7c-48f0-b43e-1d3606f91d9f"
// title: ""
// type: :text
// key: "title"
// parent_id: "5b178908-e91c-4cd0-a708-6f473ac5dde9"
export const TitleBd4acf73Db7c48f0B43e1d3606f91d9f = "Join Us";

// id: "2fb329a9-0a8d-45f3-9cf1-cb2691b6eb13"
// title: ""
// type: :text
// key: "title"
// parent_id: "71a0f418-4496-446a-a16b-2311111f560b"
export const Title2fb329a90a8d45f39cf1Cb2691b6eb13 = "About Us";

// id: "80a50f75-b257-4e83-b2d5-b3d94b019ab9"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href80a50f75B2574e83B2d5B3d94b019ab9 = "/";

// id: "0dd3e957-294d-4994-9bd3-ed90d26fd30a"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage0dd3e957294d49949bd3Ed90d26fd30a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header9a9ae194B6644b8c90673e30a5eb569a" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterAc60ed8d6bbd401e95edE1d47de6d927" {...props} />}
    </>
  );
}

// id: "4227ee35-3200-4b38-8189-bb7dc05666ef"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function SocialLinks4227ee3532004b388189Bb7dc05666ef(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks4227ee3532004b388189Bb7dc05666ef flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "4afe23f8-02f7-4db9-b914-922e38801d66"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b2aa6a0d-da1f-490e-9487-c8997814d7fa"
export function Header4afe23f802f74db9B914922e38801d66(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header4afe23f802f74db9B914922e38801d66" title="Secondary Page" {...props} />
  );
}

// id: "e5821e2d-9d10-4c62-9cdb-5d4f79beb32c"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "9fe4c632-13ff-43d7-a498-85f83e79ca4b"
export const ButtonClassE5821e2d9d104c629cdb5d4f79beb32c = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "f64e2517-b6bd-46c0-8989-2c505bb165c9"
// title: ""
// type: :text
// key: "title"
// parent_id: "88e859d2-3476-4714-a296-cf44492a9b1b"
export const TitleF64e2517B6bd46c089892c505bb165c9 = "Secondary Marketing Title 1";

// id: "ec1a90bd-a730-4d5d-a557-ef99ab7986c4"
// title: ""
// type: :text
// key: "title"
// parent_id: "677ffa2d-bd91-4438-b078-d18fd405bb45"
export const TitleEc1a90bdA7304d5dA557Ef99ab7986c4 = "Resources";

// id: "2416152f-2d06-463c-8e90-1dd96a2e562a"
// title: ""
// type: :text
// key: "class"
// parent_id: "509fb79b-7dd2-4d9f-b844-6ed8e4f132d6"
export const Class2416152f2d06463c8e901dd96a2e562a = "linked-logo";

// id: "5f74d8d1-5fc3-4fc8-98c8-984360a729cb"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage5f74d8d15fc34fc898c8984360a729cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage5f74d8d15fc34fc898c8984360a729cb gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header08290b3fC7384558Bf4eDfc917b08d67" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterFe21d42bB2b14798Bf0d8f25de347380" {...props} />}
    </div>
  );
}

// id: "769dc171-5e7d-4f2d-ad19-4d6ffeb13f76"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo509fb79b7dd24d9fB8446ed8e4f132d6", parentTag)} label={<DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="LabelBb384fb717af49a09a8e9e8f96d1093e" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation0699c5bcE9c84f8d9af3896b8ee05036", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "f64ff590-cde9-4c89-8d6b-c2bdff60579f"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "5d72e4c8-e28c-49c5-a587-4cbbafb1d19e"
export const BackgroundImageF64ff590Cde94c898d6bC2bdff60579f = "https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg";

// id: "d2ab371b-7eb7-47df-99e4-ee1ac62f8fab"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "88e859d2-3476-4714-a296-cf44492a9b1b"
export function HeaderTopD2ab371b7eb747df99e4Ee1ac62f8fab(props: any) {
  return (
    <DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopD2ab371b7eb747df99e4Ee1ac62f8fab" {...props} />
  );
}

// id: "225d79f8-a6af-4c4d-8178-a6f088b182ec"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
export function Header225d79f8A6af4c4d8178A6f088b182ec(props: any) {
  return (
    <HomeHeaderA979b7622cdd49ae946a71eb135766bf parentTag="Header225d79f8A6af4c4d8178A6f088b182ec" {...props} />
  );
}

// id: "bd283138-dbe2-4c23-9ff5-d2c49934ce13"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "da5d3e12-f160-4d18-bdaa-0f6e11d73d37"
export function HeaderTopBd283138Dbe24c239ff5D2c49934ce13(props: any) {
  return (
    <DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopBd283138Dbe24c239ff5D2c49934ce13" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0d181117-63d4-4a5f-a0b7-4504ed872b06"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "96e032c9-4d2c-46cc-89c1-e7c3ea0e4f1c"
export const NavMenuSlug0d18111763d44a5fA0b74504ed872b06 = "marketing-primary-nav";

// id: "072badac-5b14-47af-afd2-46732dc1ceee"
// title: ""
// type: :text
// key: "url"
// parent_id: "509fb79b-7dd2-4d9f-b844-6ed8e4f132d6"
export const Url072badac5b1447afAfd246732dc1ceee = "/";

// id: "8e0f1f34-1a60-430a-9973-e49acb4d0c71"
// title: ""
// type: :reference
// key: "label"
// parent_id: "3613b4b6-38dd-40bf-9ae2-374a451bf2d7"
export function Label8e0f1f341a60430a9973E49acb4d0c71(props: any) {
  return (
    <DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="Label8e0f1f341a60430a9973E49acb4d0c71" {...props} />
  );
}

// id: "5d72e4c8-e28c-49c5-a587-4cbbafb1d19e"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader5d72e4c8E28c49c5A5874cbbafb1d19e(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader5d72e4c8E28c49c5A5874cbbafb1d19e site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo3613b4b638dd40bf9ae2374a451bf2d7", parentTag)} label={<DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="Label8e0f1f341a60430a9973E49acb4d0c71" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation4c6ba97f67694bc8Ab0d0291a2c4eab4", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "677ffa2d-bd91-4438-b078-d18fd405bb45"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f6e95970-a05d-417c-bbc6-a3bc5c6fdc48"
export function Header677ffa2dBd914438B078D18fd405bb45(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header677ffa2dBd914438B078D18fd405bb45" title="Resources" {...props} />
  );
}

// id: "e8dcabb4-984f-462e-80c4-68d4124c9240"
// title: ""
// type: :reference
// key: "header"
// parent_id: "acdebf29-d623-460a-adc0-fa1bc23acade"
export function HeaderE8dcabb4984f462e80c468d4124c9240(props: any) {
  return (
    <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="HeaderE8dcabb4984f462e80c468d4124c9240" {...props} />
  );
}

// id: "0a18e341-7f57-4e89-aada-07f8a37e1e18"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "88e859d2-3476-4714-a296-cf44492a9b1b"
export function Hero0a18e3417f574e89Aada07f8a37e1e18(props: any) {
  return (
    <SecondaryMarketingHeroF1092649452f488495316ce3b24a55cb parentTag="Hero0a18e3417f574e89Aada07f8a37e1e18" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "d2699d7b-b6be-44d6-968d-76ce46d3db71"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function CopyrightD2699d7bB6be44d6968d76ce46d3db71(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightD2699d7bB6be44d6968d76ce46d3db71", parentTag)} {...props} />
  );
}

// id: "6a4ce5e2-2635-4257-a1cc-0857de7c75d5"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function SocialLinks6a4ce5e226354257A1cc0857de7c75d5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks6a4ce5e226354257A1cc0857de7c75d5 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "c9117d0b-5893-4413-a2de-c5c18cdae2c8"
// title: ""
// type: :text
// key: "href"
// parent_id: "3ce2fe30-4ecd-4dc7-b606-99c0622e897a"
export const HrefC9117d0b58934413A2deC5c18cdae2c8 = "/join";

// id: "1fdfbe68-8861-4d64-a99c-2a2f15089215"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "baa1f65b-8769-4ffc-9999-dcfa39239811"
export const NavMenuSlug1fdfbe6888614d64A99c2a2f15089215 = "footer-nav";

// id: "b2aa6a0d-da1f-490e-9487-c8997814d7fa"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header4afe23f802f74db9B914922e38801d66" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterCc782dc76149495fBc5dC924d3f53122" {...props} />}
    </div>
  );
}

// id: "052701ec-7593-44d0-ac02-bea30423250e"
// title: "Secondary Marketing Header Reference"
// type: :reference
// key: "header"
// parent_id: nil
export function Header052701ec759344d0Ac02Bea30423250e(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header052701ec759344d0Ac02Bea30423250e" {...props} />
  );
}

// id: "e36d45d6-768b-4f14-9740-2493b2c3a91e"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "acdebf29-d623-460a-adc0-fa1bc23acade"
export function SecondaryNavE36d45d6768b4f1497402493b2c3a91e(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavE36d45d6768b4f1497402493b2c3a91e", parentTag)} {...props} />
  );
}

// id: "cc782dc7-6149-495f-bc5d-c924d3f53122"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b2aa6a0d-da1f-490e-9487-c8997814d7fa"
export function FooterCc782dc76149495fBc5dC924d3f53122(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterCc782dc76149495fBc5dC924d3f53122" {...props} />
  );
}

// id: "baa1f65b-8769-4ffc-9999-dcfa39239811"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function LinksBaa1f65b87694ffc9999Dcfa39239811(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksBaa1f65b87694ffc9999Dcfa39239811", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "466ed2ff-4c42-4b32-9a40-5fbf8501399f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
export function Footer466ed2ff4c424b329a405fbf8501399f(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer466ed2ff4c424b329a405fbf8501399f" {...props} />
  );
}

// id: "44e9fe35-aba4-4152-84c3-b73e47f8c2ed"
// title: ""
// type: :reference
// key: "body"
// parent_id: "49849303-666f-4ad9-9db2-66251e09cda9"
export function Body44e9fe35Aba4415284c3B73e47f8c2ed(props: any) {
  return (
    <MarketingBodyB9538f39Ef18473bA65f96bc778834ef parentTag="Body44e9fe35Aba4415284c3B73e47f8c2ed" postSlug="about-page-posts" {...props} />
  );
}

// id: "0ea1f9b6-6bcc-4f45-9255-b1c76fc8cbf8"
// title: ""
// type: :text
// key: "title"
// parent_id: "125e8afc-745c-46b8-8c49-cb6c9893b997"
export const Title0ea1f9b66bcc4f459255B1c76fc8cbf8 = "Join Us";

// id: "7fcfd77a-90dd-49b3-8420-d2db18d313b7"
// title: ""
// type: :text
// key: "label"
// parent_id: "3ce2fe30-4ecd-4dc7-b606-99c0622e897a"
export const Label7fcfd77a90dd49b38420D2db18d313b7 = "Join Us";

// id: "14e82ae4-f498-4ecb-a632-ce94bf94d590"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote14e82ae4F4984ecbA632Ce94bf94d590(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote14e82ae4F4984ecbA632Ce94bf94d590`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "422813ea-f321-4d94-a0a2-b80378d247f7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9430ab5f-1353-4f92-aff3-a9e62ef2a446"
export function Header422813eaF3214d94A0a2B80378d247f7(props: any) {
  return (
    <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="Header422813eaF3214d94A0a2B80378d247f7" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "71a0f418-4496-446a-a16b-2311111f560b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "49849303-666f-4ad9-9db2-66251e09cda9"
export function Header71a0f4184496446aA16b2311111f560b(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header71a0f4184496446aA16b2311111f560b" title="About Us" {...props} />
  );
}

// id: "179dbd07-a32e-4832-a974-0c5115c81555"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
export function WelcomeBack179dbd07A32e4832A9740c5115c81555(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack179dbd07A32e4832A9740c5115c81555", parentTag)} {...props} />
  );
}

// id: "509fb79b-7dd2-4d9f-b844-6ed8e4f132d6"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "769dc171-5e7d-4f2d-ad19-4d6ffeb13f76"
export function LinkedLogo509fb79b7dd24d9fB8446ed8e4f132d6(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo509fb79b7dd24d9fB8446ed8e4f132d6", parentTag)} label={<DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249 parentTag="LabelBb384fb717af49a09a8e9e8f96d1093e" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "fe21d42b-b2b1-4798-bf0d-8f25de347380"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "5f74d8d1-5fc3-4fc8-98c8-984360a729cb"
export function FooterFe21d42bB2b14798Bf0d8f25de347380(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="FooterFe21d42bB2b14798Bf0d8f25de347380" {...props} />
  );
}

// id: "723e94a0-c836-4fa7-909e-f15020e33a67"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "b9538f39-ef18-473b-a65f-96bc778834ef"
export function MarketingCards723e94a0C8364fa7909eF15020e33a67(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards723e94a0C8364fa7909eF15020e33a67", parentTag)} {...props} />
  );
}

// id: "921c0589-e784-4ec6-b363-67164b0d75b9"
// title: ""
// type: :text
// key: "title"
// parent_id: "4afe23f8-02f7-4db9-b914-922e38801d66"
export const Title921c0589E7844ec6B36367164b0d75b9 = "Secondary Page";

// id: "226e65ff-b9d4-4a41-ac48-585413aa9180"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "9a9ae194-b664-4b8c-9067-3e30a5eb569a"
export const Title226e65ffB9d44a41Ac48585413aa9180 = "Login";

// id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageBd0f0eda63274b57Ab899b148bd27274(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageBd0f0eda63274b57Ab899b148bd27274 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeaderA979b7622cdd49ae946a71eb135766bf parentTag="Header225d79f8A6af4c4d8178A6f088b182ec" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack179dbd07A32e4832A9740c5115c81555", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyB9538f39Ef18473bA65f96bc778834ef parentTag="Body71e5f78739c34e4585b7Afe589831c79" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <Quote51b5154c3b1347e991c58fe18f9c1778 />}
      {props["footer"] || <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer466ed2ff4c424b329a405fbf8501399f" {...props} />}
    </div>
  );
}

// id: "08290b3f-c738-4558-bf4e-dfc917b08d67"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5f74d8d1-5fc3-4fc8-98c8-984360a729cb"
export function Header08290b3fC7384558Bf4eDfc917b08d67(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header08290b3fC7384558Bf4eDfc917b08d67" title="Public Events" {...props} />
  );
}

// id: "9e151b9a-5972-4c3c-8e0a-1ebb4ff802f1"
// title: ""
// type: :text
// key: "title"
// parent_id: "96e032c9-4d2c-46cc-89c1-e7c3ea0e4f1c"
export const Title9e151b9a59724c3c8e0a1ebb4ff802f1 = null;

// id: "c0e26b7f-79f7-42db-aea1-58eebbda72f0"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "a979b762-2cdd-49ae-946a-71eb135766bf"
export function HeaderTopC0e26b7f79f742dbAea158eebbda72f0(props: any) {
  return (
    <DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopC0e26b7f79f742dbAea158eebbda72f0" {...props} />
  );
}

// id: "6c51fe2d-98be-4fcc-866c-14df164329ff"
// title: ""
// type: :text
// key: "class"
// parent_id: "0699c5bc-e9c8-4f8d-9af3-896b8ee05036"
export const Class6c51fe2d98be4fcc866c14df164329ff = "navigation";

// id: "908916c8-8282-4a8a-b95f-b5b50bd4e547"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0699c5bc-e9c8-4f8d-9af3-896b8ee05036"
export const NavMenuSlug908916c882824a8aB95fB5b50bd4e547 = "marketing-primary-nav";

// id: "1b1de2c0-1ca1-4e37-aaf2-6bda0afb7d98"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "71e5f787-39c3-4e45-85b7-afe589831c79"
export const PostSlug1b1de2c01ca14e37Aaf26bda0afb7d98 = "home-page-posts2";

// id: "f1092649-452f-4884-9531-6ce3b24a55cb"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroF1092649452f488495316ce3b24a55cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroF1092649452f488495316ce3b24a55cb hero`}>
      <div className="title">
        {props["title"] || Title82136c4a83824d5f8b5a443fc440cce1}
      </div>
    </div>
  );
}

// id: "71e5f787-39c3-4e45-85b7-afe589831c79"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "bd0f0eda-6327-4b57-ab89-9b148bd27274"
export function Body71e5f78739c34e4585b7Afe589831c79(props: any) {
  return (
    <MarketingBodyB9538f39Ef18473bA65f96bc778834ef parentTag="Body71e5f78739c34e4585b7Afe589831c79" postSlug="home-page-posts2" {...props} />
  );
}

// id: "5b178908-e91c-4cd0-a708-6f473ac5dde9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2b8042e0-996c-4a9f-9e7e-b0d30c9c4cd8"
export function Header5b178908E91c4cd0A7086f473ac5dde9(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header5b178908E91c4cd0A7086f473ac5dde9" title="Join Us" {...props} />
  );
}

// id: "0699c5bc-e9c8-4f8d-9af3-896b8ee05036"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "769dc171-5e7d-4f2d-ad19-4d6ffeb13f76"
export function Navigation0699c5bcE9c84f8d9af3896b8ee05036(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation0699c5bcE9c84f8d9af3896b8ee05036", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "125e8afc-745c-46b8-8c49-cb6c9893b997"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d742b1c0-1334-495d-bbd2-a71fd57b1410"
export function Header125e8afc745c46b88c49Cb6c9893b997(props: any) {
  return (
    <SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b parentTag="Header125e8afc745c46b88c49Cb6c9893b997" title="Join Us" {...props} />
  );
}

// id: "84b482ac-8932-4a5e-9c71-ac565c64f4ef"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "49849303-666f-4ad9-9db2-66251e09cda9"
export function Footer84b482ac89324a5e9c71Ac565c64f4ef(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer84b482ac89324a5e9c71Ac565c64f4ef" {...props} />
  );
}

// id: "c80a99d0-8d24-452f-825e-7b1d21006175"
// title: ""
// type: :text
// key: "title"
// parent_id: "0a18e341-7f57-4e89-aada-07f8a37e1e18"
export const TitleC80a99d08d24452f825e7b1d21006175 = "Secondary Marketing Title 2";

// id: "dfb4b2c7-635a-490f-8f1c-c2d1aac3dd94"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "4c6ba97f-6769-4bc8-ab0d-0291a2c4eab4"
export const NavMenuSlugDfb4b2c7635a490f8f1cC2d1aac3dd94 = "members-primary-nav";

// id: "71a51a70-3da0-499d-8dab-62b57926772d"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "44e9fe35-aba4-4152-84c3-b73e47f8c2ed"
export const PostSlug71a51a703da0499d8dab62b57926772d = "about-page-posts";

// id: "a979b762-2cdd-49ae-946a-71eb135766bf"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderA979b7622cdd49ae946a71eb135766bf(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderA979b7622cdd49ae946a71eb135766bf site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76 parentTag="HeaderTopC0e26b7f79f742dbAea158eebbda72f0" {...props} />}
      </div>
      {props["hero"] || <HomePageHero27ff8055608d443cBe7e2369bc11eca1 parentTag="Hero5e777ed4D9c44a37B8f0Bdb57efb7909" {...props} />}
    </div>
  );
}

// id: "c8edb5d1-102a-4395-b1a9-9f09b1b015e2"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function SocialLinksC8edb5d1102a4395B1a99f09b1b015e2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksC8edb5d1102a4395B1a99f09b1b015e2 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "bc45d185-8e0f-40e9-b9c8-3d283bf2ac0c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2fb63fa6-c611-474d-8214-074a2e3f77bb"
export function HeaderBc45d1858e0f40e9B9c83d283bf2ac0c(props: any) {
  return (
    <MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37 parentTag="HeaderBc45d1858e0f40e9B9c83d283bf2ac0c" {...props} />
  );
}

// id: "163378f4-1743-4d88-8dbb-8b97c0d0801c"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function Groupflow163378f417434d888dbb8b97c0d0801c(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow163378f417434d888dbb8b97c0d0801c", parentTag)} {...props} />
  );
}

// id: "3a5808a6-5507-41cc-8348-d6a8663dc160"
// title: ""
// type: :html
// key: "logo"
// parent_id: "1d7361ee-5844-4c16-8712-a21551121464"
export function Logo3a5808a6550741cc8348D6a8663dc160(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spla/images/spla-logo.png" alt="SPLA logo" className={`${parentTag || ""} Logo3a5808a6550741cc8348D6a8663dc160 logo`} />
  );
}

// id: "7971ffa8-c936-4de7-a2c6-5fe42dca4b9c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "acdebf29-d623-460a-adc0-fa1bc23acade"
export function Footer7971ffa8C9364de7A2c65fe42dca4b9c(props: any) {
  return (
    <DefaultFooter1d7361ee58444c168712A21551121464 parentTag="Footer7971ffa8C9364de7A2c65fe42dca4b9c" {...props} />
  );
}

// id: "987f5739-3a01-4ea5-852c-f63eceb48e84"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "bd283138-dbe2-4c23-9ff5-d2c49934ce13"
export const NavMenuSlug987f57393a014ea5852cF63eceb48e84 = "members-primary-nav";

// id: "e8135b40-1764-4bfd-a536-d6e9207f6fe3"
// title: ""
// type: :text
// key: "title"
// parent_id: "08290b3f-c738-4558-bf4e-dfc917b08d67"
export const TitleE8135b4017644bfdA536D6e9207f6fe3 = "Public Events";

// id: "1d7361ee-5844-4c16-8712-a21551121464"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter1d7361ee58444c168712A21551121464(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter1d7361ee58444c168712A21551121464 footer`}>
      {props["social-links"] || <SocialLinksC8edb5d1102a4395B1a99f09b1b015e2 />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksBaa1f65b87694ffc9999Dcfa39239811", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo3a5808a6550741cc8348D6a8663dc160 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightD2699d7bB6be44d6968d76ce46d3db71", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow163378f417434d888dbb8b97c0d0801c", parentTag)} {...props} />}
    </div>
  );
}

const Components = {
  MembersAreaHeaderDa5d3e12F1604d18Bdaa0f6e11d73d37,
  ResourcesPageF6e95970A05d417cBbc6A3bc5c6fdc48,
  PostSlug4a9aac4a8bbe472280d46aff7e0acb27,
  MarketingBodyB9538f39Ef18473bA65f96bc778834ef,
  PasswordResetPage17ee66eb7e794ea6Bbbe7fc4c09ddb63,
  AdminPages6c7f6262005a4a389a3470431646fa63,
  LabelDa5d23764a8f457f8d6f7cea2347c285,
  MessagePages2fb63fa6C611474d8214074a2e3f77bb,
  JoinUsPage2b8042e0996c4a9f9e7eB0d30c9c4cd8,
  Footer28e5f418Ce2746a8B7a11c8770271bad,
  MembersAreaDefault9430ab5f13534f92Aff3A9e62ef2a446,
  AboutUsPage49849303666f4ad99db266251e09cda9,
  MarketingCards9fe4c63213ff43d7A49885f83e79ca4b,
  SignupPagesD742b1c01334495dBbd2A71fd57b1410,
  MemberPagesAcdebf29D623460aAdc0Fa1bc23acade,
  Header719000f7285e48a8B71e9cdf98a7dc41,
  DefaultLogo1ff2fd5dAf384e22Bdc234e218a92249,
  HomePageHero27ff8055608d443cBe7e2369bc11eca1,
  Header9a9ae194B6644b8c90673e30a5eb569a,
  LinkedLogo3613b4b638dd40bf9ae2374a451bf2d7,
  Navigation4c6ba97f67694bc8Ab0d0291a2c4eab4,
  Hero5e777ed4D9c44a37B8f0Bdb57efb7909,
  Button3ce2fe304ecd4dc7B60699c0622e897a,
  NavMenuSlug5947ee43Cb5e47789472161650bb5c93,
  LabelBb384fb717af49a09a8e9e8f96d1093e,
  DefaultHeader96e032c94d2c46cc89c1E7c3ea0e4f1c,
  SecondaryMarketingHeader88e859d234764714A296Cf44492a9b1b,
  FooterAc60ed8d6bbd401e95edE1d47de6d927,
  Footer94e8411f4ae24ec2B85323ed2945cf9a,
  Alt5130caf7229145a2B2d86c5261409a56,
  Title82136c4a83824d5f8b5a443fc440cce1,
  Quote51b5154c3b1347e991c58fe18f9c1778,
  TitleBd4acf73Db7c48f0B43e1d3606f91d9f,
  Title2fb329a90a8d45f39cf1Cb2691b6eb13,
  Href80a50f75B2574e83B2d5B3d94b019ab9,
  LoginPage0dd3e957294d49949bd3Ed90d26fd30a,
  SocialLinks4227ee3532004b388189Bb7dc05666ef,
  Header4afe23f802f74db9B914922e38801d66,
  ButtonClassE5821e2d9d104c629cdb5d4f79beb32c,
  TitleF64e2517B6bd46c089892c505bb165c9,
  TitleEc1a90bdA7304d5dA557Ef99ab7986c4,
  Class2416152f2d06463c8e901dd96a2e562a,
  PublicEventsPage5f74d8d15fc34fc898c8984360a729cb,
  DefaultHeaderTop769dc1715e7d4f2dAd194d6ffeb13f76,
  BackgroundImageF64ff590Cde94c898d6bC2bdff60579f,
  HeaderTopD2ab371b7eb747df99e4Ee1ac62f8fab,
  Header225d79f8A6af4c4d8178A6f088b182ec,
  HeaderTopBd283138Dbe24c239ff5D2c49934ce13,
  NavMenuSlug0d18111763d44a5fA0b74504ed872b06,
  Url072badac5b1447afAfd246732dc1ceee,
  Label8e0f1f341a60430a9973E49acb4d0c71,
  ShortHeader5d72e4c8E28c49c5A5874cbbafb1d19e,
  Header677ffa2dBd914438B078D18fd405bb45,
  HeaderE8dcabb4984f462e80c468d4124c9240,
  Hero0a18e3417f574e89Aada07f8a37e1e18,
  CopyrightD2699d7bB6be44d6968d76ce46d3db71,
  SocialLinks6a4ce5e226354257A1cc0857de7c75d5,
  HrefC9117d0b58934413A2deC5c18cdae2c8,
  NavMenuSlug1fdfbe6888614d64A99c2a2f15089215,
  SecondaryPageB2aa6a0dDa1f490e9487C8997814d7fa,
  Header052701ec759344d0Ac02Bea30423250e,
  SecondaryNavE36d45d6768b4f1497402493b2c3a91e,
  FooterCc782dc76149495fBc5dC924d3f53122,
  LinksBaa1f65b87694ffc9999Dcfa39239811,
  Footer466ed2ff4c424b329a405fbf8501399f,
  Body44e9fe35Aba4415284c3B73e47f8c2ed,
  Title0ea1f9b66bcc4f459255B1c76fc8cbf8,
  Label7fcfd77a90dd49b38420D2db18d313b7,
  PageQuote14e82ae4F4984ecbA632Ce94bf94d590,
  Header422813eaF3214d94A0a2B80378d247f7,
  Header71a0f4184496446aA16b2311111f560b,
  WelcomeBack179dbd07A32e4832A9740c5115c81555,
  LinkedLogo509fb79b7dd24d9fB8446ed8e4f132d6,
  FooterFe21d42bB2b14798Bf0d8f25de347380,
  MarketingCards723e94a0C8364fa7909eF15020e33a67,
  Title921c0589E7844ec6B36367164b0d75b9,
  Title226e65ffB9d44a41Ac48585413aa9180,
  HomePageBd0f0eda63274b57Ab899b148bd27274,
  Header08290b3fC7384558Bf4eDfc917b08d67,
  Title9e151b9a59724c3c8e0a1ebb4ff802f1,
  HeaderTopC0e26b7f79f742dbAea158eebbda72f0,
  Class6c51fe2d98be4fcc866c14df164329ff,
  NavMenuSlug908916c882824a8aB95fB5b50bd4e547,
  PostSlug1b1de2c01ca14e37Aaf26bda0afb7d98,
  SecondaryMarketingHeroF1092649452f488495316ce3b24a55cb,
  Body71e5f78739c34e4585b7Afe589831c79,
  Header5b178908E91c4cd0A7086f473ac5dde9,
  Navigation0699c5bcE9c84f8d9af3896b8ee05036,
  Header125e8afc745c46b88c49Cb6c9893b997,
  Footer84b482ac89324a5e9c71Ac565c64f4ef,
  TitleC80a99d08d24452f825e7b1d21006175,
  NavMenuSlugDfb4b2c7635a490f8f1cC2d1aac3dd94,
  PostSlug71a51a703da0499d8dab62b57926772d,
  HomeHeaderA979b7622cdd49ae946a71eb135766bf,
  SocialLinksC8edb5d1102a4395B1a99f09b1b015e2,
  HeaderBc45d1858e0f40e9B9c83d283bf2ac0c,
  Groupflow163378f417434d888dbb8b97c0d0801c,
  Logo3a5808a6550741cc8348D6a8663dc160,
  Footer7971ffa8C9364de7A2c65fe42dca4b9c,
  NavMenuSlug987f57393a014ea5852cF63eceb48e84,
  TitleE8135b4017644bfdA536D6e9207f6fe3,
  DefaultFooter1d7361ee58444c168712A21551121464
}

export default Components;